import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import Arrow from '@/assets/img/icons/icon_white_arrow_24x25@2x.png';
import Bn1 from '@/assets/img/tmp/involve/party_bn1@2x.jpg';
import Bn1M from '@/assets/img/tmp/involve/party_bn1M@2x.jpg';
import Bn2 from '@/assets/img/tmp/involve/party_bn2@2x.png';
import Bn2M from '@/assets/img/tmp/involve/party_bn2M@2x.png';
import Bn3 from '@/assets/img/tmp/involve/party_bn3@2x.png';
import Bn3M from '@/assets/img/tmp/involve/party_bn3M@2x.png';
import DtObj from '@/assets/img/tmp/involve/party_rectangle@2x.png';
import TitObj1 from '@/assets/img/tmp/involve/party_tit_obj1@2x.png';
import TitObj2 from '@/assets/img/tmp/involve/party_tit_obj2@2x.png';
import TitObj3 from '@/assets/img/tmp/involve/party_tit_obj3@2x.png';
import Triangle from '@/assets/img/tmp/involve/party_triangle@2x.png';
import TriangleM from '@/assets/img/tmp/involve/party_triangleM@2x.png';
import Obj1 from '@/assets/img/tmp/involve/partyobj1@2x.png';
import Obj2 from '@/assets/img/tmp/involve/partyobj2@2x.png';
import Obj3 from '@/assets/img/tmp/involve/partyobj3@2x.png';
import Obj4 from '@/assets/img/tmp/involve/partyobj4@2x.png';
import Obj5 from '@/assets/img/tmp/involve/partyobj5@2x.png';
import Obj6 from '@/assets/img/tmp/involve/partyobj6@2x.png';
import Obj7 from '@/assets/img/tmp/involve/partyobj7@2x.png';
import Obj8 from '@/assets/img/tmp/involve/partyobj8@2x.png';
import Button from '@/components/Button';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import { ObjList } from '@/components/Css/PublicPrivatePartnership';
import Image from '@/components/Image';
import PartyLayerPopup from '@/components/Layer/PartyLayerPopup';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';

const eventData = [
  {
    id: 0,
    img: Obj1,
    tit: `생일 파티, 사교 파티, 결혼식, 창립기념일 등 각종 축하 파티`,
  },
  {
    id: 1,
    img: Obj2,
    tit: `학급 친구들과 함께`,
  },
  {
    id: 2,
    img: Obj3,
    tit: `대학교 선·후배 <br />동아리 활동`,
  },
  {
    id: 3,
    img: Obj4,
    tit: `회사 내 동호회 및 <br />바자회`,
  },
  {
    id: 4,
    img: Obj5,
    tit: `팬클럽 회원들과  <br />마음 모으기`,
  },
  {
    id: 5,
    img: Obj6,
    tit: `갤러리, 전시회, 음악회`,
  },
  {
    id: 6,
    img: Obj7,
    tit: `운동경기 및 <br />자선 스포츠 대회`,
  },
  {
    id: 7,
    img: Obj8,
    tit: `금연, 다이어트, 금주, <br />취업 등 새로운 결심 알리기`,
  },
];
const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `후원자모집팀`,
    tel: `02-721-5115`,
    email: `igshop@unicef.or.kr`,
  },
];

const ProcessContainer = styled.div`
  padding-bottom: 240px;
  article {
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 0;
    }

    .dl-flex {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    dl {
      width: 33.33%;

      dt {
        position: relative;
        background: #1cabe2;
        min-height: 96px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        ${Tit} {
          padding: 0 24px;
          position: relative;
          z-index: 1;
          line-height: 96px;
        }
        &:before {
          content: '';
          display: block;
          width: 24px;
          height: 24px;
          background: url(${Arrow}) center no-repeat;
          background-size: cover;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
          opacity: 0;
        }
      }
      dd {
        align-items: flex-start;
        padding: 32px 24px 64px;
        .blit-list {
          margin-top: 16px;
        }
        ${Tit} {
          display: block;
        }
        ${Button} {
          margin-top: 48px;
          max-width: 160px;
          width: 100%;
          background: #fff;
        }

        .col {
          width: 50%;
        }
      }
    }

    &:first-child {
      dl:nth-child(1) {
        width: 66.66%;
        dt {
          &:before {
            opacity: 1;
          }
        }
      }
      dl:last-child {
        dt {
          &:after {
            content: '';
            display: block;
            width: 400px;
            height: 100%;
            background: #1cabe2;
            position: absolute;
            left: 100%;
            top: 0;
          }
        }
      }
    }
    &:last-child {
      dl:nth-child(1),
      dl:nth-child(2) {
        dt {
          &:before {
            opacity: 1;
          }
        }
      }
      dl:nth-child(2) {
        dt {
          ${Tit} {
            position: relative;
            &:after {
              content: '';
              display: block;
              width: 265px;
              height: 133px;
              background: url(${DtObj}) center no-repeat;
              background-size: cover;
              position: absolute;
              left: 50%;
              top: -23px;
              transform: translate(-50%, 0);
              z-index: 1;
            }
          }
        }
      }
      dl:last-child {
        dt {
          &:after {
            content: '';
            display: block;
            width: 65px;
            height: 162px;
            background: #f8f9fa url(${Triangle}) center no-repeat;
            background-size: cover;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
          }
        }
      }
    }
    .box {
      width: 100%;
      padding: 32px;
      padding-bottom: 40px;
      background-color: #e2f0f6;

      ${Tit} {
        position: relative;
        padding: 13px 0;
        padding-left: 80px;

        &::before {
          content: '';
          display: block;
          width: 56px;
          height: 56px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          position: absolute;
          left: 0;
          top: 0;
        }

        &.obj1 {
          &::before {
            background-image: url(${TitObj1});
          }
        }
        &.obj2 {
          &::before {
            background-image: url(${TitObj2});
          }
        }
        &.obj3 {
          &::before {
            background-image: url(${TitObj3});
          }
        }
      }
      .blit-list {
        margin-top: 16px;
        strong {
          color: #1cabe2;
        }
      }
    }

    .box-flex {
      align-items: stretch;
      & > li {
        width: 50%;

        .box {
          height: 100%;
        }
      }
    }

    .cnt-list {
      margin-top: 24px;
      & > li {
        position: relative;
        padding-left: 29px;

        .no {
          position: absolute;
          left: 0;
          top: 0;
          color: #1cabe2;
        }

        strong {
          color: #1cabe2;
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    padding-bottom: 120px;
    margin-left: -20px;
    margin-right: -20px;
    article {
      margin-bottom: 48px;

      dl {
        width: 100%;

        dt {
          min-height: 72px;
          justify-content: center;
          line-height: 72px;
          &:before {
            display: none;
          }
        }
        dd {
          padding: 24px 20px 48px;
          .blit-list {
            margin-top: 8px;
          }
          ${Button} {
            margin-top: 32px;
          }
        }
      }

      &:first-child {
        dl:nth-child(1) {
          width: 100%;
        }
        .box {
          margin-bottom: 48px;
        }
      }

      &:last-child {
        dl:nth-child(2) {
          dt {
            ${Tit} {
              position: relative;
              &:after {
                content: '';
                display: block;
                width: 265px;
                height: 133px;
                background: url(${DtObj}) center no-repeat;
                background-size: cover;
                position: absolute;
                left: 50%;
                top: -23px;
                transform: translate(-50%, 0);
                z-index: 1;
              }
            }
          }
        }
        dl:last-child {
          dt {
            &:after {
              width: 60px;
              height: 100px;
              background-image: url(${TriangleM});
            }
          }
        }
      }
      .box {
        margin: 0 20px;
        width: auto;
        padding: 24px;
        background-color: #e2f0f6;

        ${Tit} {
          padding-left: 72px;
        }
      }

      .box-flex {
        & > li {
          width: 100%;
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .cnt-list {
        margin-top: 16px;
        & > li {
          padding-left: 17px;
        }
      }
    }
  }
`;
const SectionTop = styled(Section)`
  background: #e2f0f6;

  ${ObjList} {
    align-items: stretch;
    & > li {
      width: 25%;
      margin-bottom: 48px;

      &:last-child {
        margin-bottom: 0;
      }

      .img-wrap {
        ${Image} {
          max-width: 160px;
          display: inline-block;
        }
      }
      .dec-wrap {
        text-align: center;
        margin-top: 24px;

        ${Tit} {
          line-height: 1.6;
          letter-spacing: -1.2;
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    ${ObjList} {
      justify-content: center;
      & > li {
        width: 33.33%;
        margin-bottom: 32px;

        .img-wrap {
          ${Image} {
            max-width: 80px;
          }
        }
        .dec-wrap {
          margin-top: 16px;
        }
      }
    }
  }
  ${breakpoint(`mobile`)} {
    ${ObjList} {
      & > li {
        width: 50%;
        .dec-wrap {
          ${Tit} {
            max-width: 140px;
            display: inline-block;
          }
        }
      }
    }
  }
`;
const SectionBn = styled(Section)`
  ${breakpoint(640)} {
    .bn {
      width: 100%;
    }
  }
`;
const SectionStory = styled(Section)`
  .bn {
    margin-top: 96px;
    display: block;
  }
  ${breakpoint(640)} {
    .bn {
      width: 100%;
    }
  }
`;
const SectionProcess = styled(Section)`
  background: #f8f9fa;

  .article-btt {
    .blit-list {
      margin-top: 32px;
    }
  }
  ${breakpoint(`tablet`)} {
    .article-btt {
      .blit-list {
        margin-top: 16px;
      }
    }
  }
`;
const SectionInfo = styled(Section)`
  ${SectionHeader} {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${Button} {
      width: 352px;
    }
    ${breakpoint(`tablet`)} {
      margin-left: 0;
      margin-right: 0;
      h2 {
        padding: 0;
      }
      ${Button} {
        width: 100%;
        margin-top: 57px;
      }
    }
    ${breakpoint(`mobile`)} {
      display: block;
    }
  }
`;

const PartyForUnicef: React.FC<PageProps> = ({ location }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [PopupTitle, setPopupTitle] = useState(``);
  const [PopupSid, setPopupSid] = useState<PopupData | string | number>(``);

  const open = () => setPopupOpen(true);
  const close = () => setPopupOpen(false);

  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['293'],
        pagePerCount: 12,
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/involve/individual/party-for-unicef-detail/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadThumbs();
  }, [loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="Party for UNICEF"
      description="for every child, a special party"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                <PageComponent id="title1">
                  여러분의 소중한 인연들과 <br />
                  재미있는 이벤트를 계획하세요
                </PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                즐겁고 기쁜 날, 지구촌 어린이를 위한 모금활동을 직접 기획하고,
                <br />
                파티를 통해 모인 후원금을 기부할 수 있습니다
              </PageComponent>
            </p>
          </SectionHeader>
          <ObjList className="flex">
            {eventData.map((row, index) => (
              <li key={index}>
                <div className="img-wrap">
                  <Image pcSrc={row.img} mobileSrc={row.img} />
                </div>
                <div className="dec-wrap">
                  <Tit
                    size="s4"
                    className="dec"
                    color="sky"
                    dangerouslySetInnerHTML={{ __html: row.tit }}
                  />
                </div>
              </li>
            ))}
          </ObjList>
        </Container>
      </SectionTop>

      <SectionBn className="by-sub-main-layout">
        <Container>
          <button
            type="button"
            className="bn"
            title="파티신청-직접 모금활동을 기획하고, 파티로 후원금을 모아보세요"
            onClick={() => {
              open();
              setPopupTitle(`Party for UNICEF 신청`);
              setPopupSid(1);
            }}
          >
            <Image pcSrc={Bn1} mobileSrc={Bn1M} />
          </button>
        </Container>
      </SectionBn>

      <SectionStory className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">파티이야기</Tit>
              </h2>
              <LinkSafe
                to="/involve/individual/party-for-unicef-detail"
                className="more"
              >
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate
              isSwiper
              overflowHidden
            />
          )}
          <button
            type="button"
            title="파티신청-파티를 진행하고 싶으신가요?"
            onClick={() => {
              open();
              setPopupTitle(`Party for UNICEF 신청`);
              setPopupSid(1);
            }}
            className="bn"
          >
            <Image pcSrc={Bn3} mobileSrc={Bn3M} />
          </button>
          <button
            type="button"
            title="후기작성-이미 파티를 완료하셨나요?"
            className="bn"
            onClick={() => {
              open();
              setPopupTitle(`Party for UNICEF 활동후기`);
              setPopupSid(2);
            }}
          >
            <Image pcSrc={Bn2} mobileSrc={Bn2M} />
          </button>
        </Container>
      </SectionStory>

      <SectionProcess className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <h2>
              <Tit size="s1">
                Party for UNICEF <br />
                신청 및 활동 절차
              </Tit>
            </h2>
          </SectionHeader>
          <ProcessContainer>
            <article>
              <div className="dl-flex">
                <dl>
                  <dt>
                    <Tit size="s3" color="white">
                      파티 시작 2주 전
                    </Tit>
                  </dt>
                  <dd className="flex">
                    <div className="col">
                      <Tit size="s4">파티 신청</Tit>
                      <ul className="blit-list">
                        <li>주최자 인적사항 등록</li>
                        <li>파티계획서 제출</li>
                        <li>유의사항 동의</li>
                      </ul>
                      <Button
                        title="신청 및 활동 절차-파티신청"
                        outline
                        onClick={() => {
                          open();
                          setPopupTitle(`Party for UNICEF 신청`);
                          setPopupSid(1);
                        }}
                      >
                        파티 신청
                      </Button>
                    </div>
                    <div className="col">
                      <Tit size="s4">심사 및 허가</Tit>
                      <ul className="blit-list">
                        <li>적격심사 후 통보</li>
                        <li>파티 허가증 발급</li>
                      </ul>
                    </div>
                  </dd>

                  <div className="box pc-hide">
                    <Tit size="s4" className="obj1">
                      파티 시작 전
                    </Tit>
                    <ul className="blit-list">
                      <li>
                        Party for UNICEF 활동은{` `}
                        <strong>반드시 유니세프한국위원회의 허가</strong>에 따라
                        이루어져야 합니다.
                      </li>
                      <li>
                        파티 참가자들과 <strong>함께 달성할 모금 목표액</strong>
                        을 정하세요. 공동의 목표를 정하고, 한마음으로 어린이들을
                        응원하면 더 많은 후원금을 모을 수 있습니다.
                      </li>
                    </ul>
                  </div>
                </dl>
                <dl>
                  <dt>
                    <Tit size="s3" color="white">
                      파티 시작 1주 전
                    </Tit>
                  </dt>
                  <dd>
                    <Tit size="s4">키트 발송</Tit>
                    <Button
                      title="신청 및 활동 절차-키트안내"
                      outline
                      onClick={() => {
                        open();
                        setPopupTitle(
                          `Party for UNICEF 활동 물품 키트 (10인용 기준)`,
                        );
                        setPopupSid(0);
                      }}
                    >
                      키트 안내
                    </Button>
                  </dd>
                </dl>
              </div>
              <div className="box m-hide">
                <Tit size="s4" className="obj1">
                  파티 시작 전
                </Tit>
                <ul className="blit-list">
                  <li>
                    Party for UNICEF 활동은{` `}
                    <strong>반드시 유니세프한국위원회의 허가</strong>에 따라
                    이루어져야 합니다.
                  </li>
                  <li>
                    파티 참가자들과 <strong>함께 달성할 모금 목표액</strong>을
                    정하세요. 공동의 목표를 정하고, 한마음으로 어린이들을
                    응원하면 더 많은 후원금을 모을 수 있습니다.
                  </li>
                </ul>
              </div>
            </article>
            <article>
              <div className="dl-flex">
                <dl>
                  <dt>
                    <Tit size="s3" color="white">
                      파티 시작 3일 전
                    </Tit>
                  </dt>
                  <dd>
                    <Tit size="s4">활동 안내 및 교육</Tit>
                    <ul className="blit-list">
                      <li>활동 유의사항 안내 및 교육</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <Tit size="s3" color="white">
                      Party for UNICEF DAY
                    </Tit>
                  </dt>
                  <dd>
                    <Tit size="s4">모금 파티 시작</Tit>
                    <ul className="blit-list">
                      <li>신청한 날짜에 파티 진행</li>
                    </ul>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <Tit size="s3" color="white">
                      파티 종료 후 1주 이내
                    </Tit>
                  </dt>
                  <dd>
                    <Tit size="s4">후원금 전달 및 활동 후기 작성</Tit>
                    <ul className="blit-list">
                      <li>후원금 송금</li>
                      <li>활동 후기 작성 및 제출</li>
                    </ul>

                    <Button
                      title="신청 및 활동 절차-후기작성"
                      outline
                      onClick={() => {
                        open();
                        setPopupTitle(`Party for UNICEF 활동후기`);
                        setPopupSid(2);
                      }}
                    >
                      후기 작성
                    </Button>
                  </dd>
                </dl>
              </div>
              <ul className="box-flex flex">
                <li>
                  <div className="box">
                    <Tit size="s4" className="obj2">
                      파티 진행
                    </Tit>
                    <ul className="blit-list">
                      <li>
                        파티를 즐기며 다양한 방법으로{` `}
                        <strong>후원에 참여</strong>
                        해보세요!
                      </li>
                    </ul>
                    <ul className="cnt-list">
                      <li>
                        <span className="no">①</span>
                        {` `}
                        <strong>Party for UNICEF 페이지에 접속</strong>하여
                        후원신청
                      </li>
                      <li>
                        <span className="no">②</span> 파티 신청일에 맞춰{` `}
                        <strong>즐겁게 파티 진행</strong>
                      </li>
                      <li>
                        <span className="no">③</span> 파티 참가자들의 후원금을
                        {` `}
                        <strong>
                          주최자가 모아 안내받은 유니세프 계좌에 입금
                        </strong>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div className="box">
                    <Tit size="s4" className="obj3">
                      파티가 끝난 후
                    </Tit>
                    <ul className="blit-list">
                      <li>
                        <strong>
                          종료 후 1주일 내에 반드시 활동 후기를 작성
                        </strong>
                        해주세요. '유니세프와 함께한 파티 이야기'에 소개되고,
                        {` `}
                        <strong>
                          우수 활동 팀을 선정하여 유니세프가 감사 선물을
                          드립니다.
                        </strong>
                      </li>
                      <li>
                        기부금 영수증 발급을 원하시는 경우,{` `}
                        <strong>
                          활동 후기에 성명, 주민등록번호, 주소, 기부금액을
                          정확하게 기입
                        </strong>
                        해 주세요. (개인별 기부금 영수증을 발급받으실 경우, 단체
                        기부금 영수증은 발급되지 않습니다.)
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </article>
          </ProcessContainer>
          <article className="article-btt">
            <h3>
              <Tit size="s2">진행 시 주의사항</Tit>
            </h3>
            <ul className="blit-list">
              <li>
                Party for UNICEF 로고 이외의{` `}
                <strong>유니세프 로고를 무단으로 사용할 수 없습니다.</strong>
              </li>
              <li>
                <strong>'유니세프 모금함'은 사용하실 수 없습니다.</strong>
              </li>
              <li>
                유니세프가 <strong>허가한 장소 및 시간</strong>에만 모금활동을
                할 수 있습니다.
              </li>
              <li>
                Party for UNICEF를{` `}
                <strong>
                  사칭한 모금 행위, 후원금 갈취 등 기부 이외의 목적으로 이윤을
                  추구하는 행위
                </strong>
                는 관계 법령에 따라 형사 처벌 될 수 있습니다.
              </li>
              <li>
                유니세프의 브랜드 이미지를 훼손하는 행동
                <strong>
                  (지나친 음주, 흡연, 폭력, 유해물질 제공 등)은 절대 삼가
                </strong>
                해야 합니다.
              </li>
              <li>
                <strong>키트는 허가받은 활동에 한하여 1회 사용</strong>할 수
                있습니다.
              </li>
              <li>
                사전에 활동 장소의 <strong>안전성을 점검</strong>하고 정확한
                {` `}
                <strong>참가 인원을 파악</strong>하여, 안전사고에 철저히
                대비하세요.
              </li>
              <li>
                모금 활동 중 일어난 사고 및 위험상황에 대한 책임은{` `}
                <strong>유니세프한국위원회가 아닌 파티 주최자</strong>에게
                있습니다.
              </li>
              <li>
                위에 명시되지 않은 기타 사항은{` `}
                <strong>유니세프한국위원회와 상의하여 진행</strong>합니다.
              </li>
            </ul>
          </article>
        </Container>
      </SectionProcess>

      <SectionInfo className="by-sub-main-layout">
        <Container>
          <SectionHeader
            className="with-desc t2 flex"
            css={`
              ${breakpoint(640)} {
                display: block;
                margin: 0;
              }
            `}
          >
            <h2
              css={`
                ${breakpoint(640)} {
                  padding: 0;
                }
              `}
            >
              <Tit size="s1-5">Party for UNICEF 안내자료</Tit>
            </h2>
            <div>
              <Button
                ico="down"
                outline
                onClick={() =>
                  window.open(
                    `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L2RvYw%3D%3D&dispFileNm=PartyforUNICEF_Guidebook.pdf&svrFileNm=PartyforUNICEF_Guidebook.pdf`,
                  )
                }
              >
                <span className="ico">다운로드</span>
              </Button>
            </div>
          </SectionHeader>
        </Container>
      </SectionInfo>

      <Section className="by-sub-main-layout">
        <Container>
          <ContactUs itemData={contactData} />
        </Container>
      </Section>

      <PartyLayerPopup
        isOpen={popupOpen}
        PopTitle={PopupTitle}
        PopSid={PopupSid}
        onRequestClose={close}
      />
    </LayoutWithTitle>
  );
};

export default PartyForUnicef;
